import React, { useEffect, useState } from 'react'
import DIT from '@/components/Organisms/DIT'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'
import { useActions, useAppSelector } from '@/hooks'
import ErrorBoundary from '@/components/Organisms/ErrorBoundary'
import {
  useGetDitsQuery,
  useGetDitStatusQuery,
} from '@/components/Organisms/DIT/service'
import { useParams } from 'react-router-dom'
import { infoIconsSelector } from '@/modules/device-module/store/infoIconsSlice'
import { useGetUserProfileQuery } from '@/modules/user/services/userService'
import { UserTypes } from '@/types/typeUser'

const useHasFocus = () => {
  // get the initial state
  const [focus, setFocus] = useState(document.hasFocus())
  useEffect(() => {
    // helper functions to update the status
    const onFocus = () => setFocus(true)
    const onBlur = () => setFocus(false)

    // assign the listener
    // update the status on the event
    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)

    // remove the listener
    return () => {
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
    }
  }, [])

  // return the status
  return focus
}

const DITPage = () => {
  const { id } = useParams()
  // const [skipFirstRequest, setSkipFirstRequest] = useState(false)
  const { data: currentUserData } = useGetUserProfileQuery('0')
  const { refetch } = useGetDitsQuery(Number(id))
  // const focus = useHasFocus()
  // skip first 15000 because we have this data
  useGetDitStatusQuery(Number(id), {
    pollingInterval: 15000,
    // skip: !focus || skipFirstRequest,
  })
  const programList = useAppSelector(ditSliceSelectors.stateDitPrograms)
  const { ditData } = useAppSelector(infoIconsSelector)
  const { ditSetDitSize, ditSetDisabledPrograms, ditReset } = useActions()
  useEffect(() => {
    ditReset()
    refetch()
    // setTimeout(() => {
    //   setSkipFirstRequest(false)
    // }, 14000)
  }, [])
  useEffect(() => {
    const activeProgram = programList.find((program) => program.index === 1000)
    if (activeProgram) {
      ditSetDisabledPrograms([activeProgram.id])
    }
    if (currentUserData?.data?.typeId === UserTypes.user) {
      const disabledProgramIds = programList.map((program) => program.id)
      ditSetDisabledPrograms(disabledProgramIds)
    }
  }, [programList])

  useEffect(() => {
    if (ditData?.width && ditData?.height) {
      ditSetDitSize({ width: ditData?.width, height: ditData?.height })
    }
  }, [ditData])

  return (
    <ErrorBoundary>
      <DIT
        data={programList}
        disableButtons={currentUserData?.data?.typeId === UserTypes.user}
      />
    </ErrorBoundary>
  )
}

export default DITPage
