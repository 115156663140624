import React, { useEffect, useState } from 'react'
import Tooltip from '@mui/material/Tooltip'
import cn from 'classnames'
import styles from '@/components/Organisms/DIT/DITTree/DITTree.module.scss'
import DialogChanges from '@/components/Dialog/DialogChanges'
import { IconButton } from '@mui/material'
import IconDelete from '@/components/Atoms/Icons/IconDelete'
import type { Program } from '@/components/Organisms/DIT/types'
import IconDown from '@/components/Atoms/Icons/IconDown'
import IconRight from '@/components/Atoms/Icons/IconRight'
import DITISlides from '@/components/Organisms/DIT//DITTree/DITSlides'
import { useActions, useAppSelector } from '@/hooks'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import DITProgramState from '@/components/Organisms/DIT/DITTree/DITProgramState'
import DITRadio from '@/components/Organisms/DIT/DITTree/DITRadio'
import IconAddDoc from '@/components/Atoms/Icons/IconAddDoc'
import IconAddDoc2 from '@/components/Atoms/Icons/IconAddDoc2'
type Props = {
  editMode: boolean
  program: Program
}
const RESIZE_NODE_CLASS = 'react-resizable-handle'
const RESIZE_NODE_CLASS_SHOW = 'react-resizable-handle-show'

const DITProgram: React.FC<Props> = ({ program, editMode }) => {
  const [modalRemove, setModalRemove] = React.useState(false)
  const activeProgram = useAppSelector(ditSliceSelectors.stateDitActiveProgram)
  const [programCollapsed, setProgramCollapsed] = React.useState(true)
  const form = useAppSelector(ditSliceSelectors.stateRelationForm)

  const { ditSetActiveProgram, ditRemoveProgram, ditUpdateProgramName } =
    useActions()

  // const disableContext = (e) => {
  //   if (e.keyCode === 17) {
  //     e.preventDefault()
  //   }
  // }
  /*
  const handleEnabling = (e: any) => {
    if (e.keyCode === 16) {
      // e.preventDefault()
      const elemResize = document.getElementsByClassName(RESIZE_NODE_CLASS)
      if (elemResize.length) {
        elemResize[0].addEventListener('contextmenu', (e) => {
          e.preventDefault()
        })
        elemResize[0].classList.add(RESIZE_NODE_CLASS_SHOW)
      }
    }
  }
  const handleDisable = (e: any) => {
    if (e.keyCode === 16) {
      const elemResize = document.getElementsByClassName(RESIZE_NODE_CLASS)
      if (elemResize.length) {
        elemResize[0].classList.remove(RESIZE_NODE_CLASS_SHOW)
      }
    }
  }
  useEffect(() => {
    window.addEventListener('keydown', handleEnabling)
    window.addEventListener('keyup', handleDisable)
    // window.addEventListener('contextmenu', disableContext)
    return () => {
      window.removeEventListener('keyup', handleDisable)
      window.removeEventListener('keydown', handleEnabling)
      // window.addEventListener('contextmenu', disableContext)
    }
  }, [])
*/

  const toggleProgramCollapsed = () => {
    setProgramCollapsed(!programCollapsed)
  }
  const handleActiveProgram = () => {
    ditSetActiveProgram(program.id)
  }
  const removeHandler = () => {
    setModalRemove(true)
  }
  const approvedRemoveHandler = () => {
    ditRemoveProgram({ pIndex: program.id, pId: program.id })
    setModalRemove(false)
  }
  const handleChangeName = (e: any) => {
    ditUpdateProgramName({ name: e.target.value, id: program.id })
  }
  const isActive = program.id === activeProgram

  let iconTest = <IconAddDoc />
  if (program.relationShip) {
    iconTest = <IconAddDoc2 />
  }
  if (isActive && form?.switchState) {
    iconTest = <IconAddDoc2 />
  }
  return (
    <div className={styles.ProgramWrapper}>
      <DialogChanges
        open={modalRemove}
        handleAgree={approvedRemoveHandler}
        handleClose={() => setModalRemove(false)}
        handleDisAgree={() => setModalRemove(false)}
        btnSaveTitle={'Удалить'}
        title={'Удалить элемент?'}
        message={
          'ВНИМАНИЕ! Если элемент содержит вложения - они будут также удалены ?'
        }
      />
      <div
        className={cn(styles.ProgramRow, {
          [styles.ProgramTitleActive]: isActive,
        })}
      >
        <div className={styles.ProgramRowLeft}>
          <DITRadio
            program={program}
            editMode={editMode}
          />
          <span
            style={{
              visibility: program.slides.length ? 'visible' : 'hidden',
            }}
            onClick={toggleProgramCollapsed}
            className={styles.ToggleArrow}
          >
            {programCollapsed ? <IconRight /> : <IconDown />}
          </span>
          {editMode ? (
            <>
              <input
                defaultValue={program.name}
                onChange={handleChangeName}
                className={styles.EditableNameInput}
                type='text'
              />
            </>
          ) : (
            <span onClick={handleActiveProgram}>
              <DITProgramState
                name={program.name}
                id={program.id}
                programIcon={iconTest}
              />
            </span>
          )}
        </div>
        {editMode && (
          <IconButton onClick={removeHandler}>
            <IconDelete />
          </IconButton>
        )}
      </div>
      {!programCollapsed && (
        <DITISlides
          program={program}
          slides={program.slides}
          editMode={editMode}
        />
      )}
      {/*{!programCollapsed &&*/}
      {/*  program.slides.map((slide) => (*/}
      {/*    <DITISlides*/}
      {/*      program={program}*/}
      {/*      programId={program.index}*/}
      {/*      key={slide.index}*/}
      {/*      slide={slide}*/}
      {/*      editMode={editMode}*/}
      {/*    />*/}
      {/*  ))}*/}
    </div>
  )
}

export default DITProgram
