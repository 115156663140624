import { Backdrop, CircularProgress } from '@mui/material'
import { OverridableStringUnion } from '@mui/types'
import { CircularProgressPropsColorOverrides } from '@mui/material/CircularProgress/CircularProgress'
import { FC } from 'react'
import { Portal } from '@mui/base'

interface ILoader {
  color?: OverridableStringUnion<
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'inherit',
    CircularProgressPropsColorOverrides
  >
  open: boolean
}

const LoaderFullScreen: FC<ILoader> = ({ open, color }) => {
  return (
    <Portal>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color={color} />
      </Backdrop>
    </Portal>
  )
}

export default LoaderFullScreen
