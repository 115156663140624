import React from 'react'
import { LoadingButton } from '@mui/lab'
import {
  useCreateCopyProgramMutation,
  useGetDitsQuery,
  useCreateCopySlideMutation,
} from '@/components/Organisms/DIT/service'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '@/hooks'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'
import { LoaderFullScreen } from '@/ui'
import DialogChanges from '@/components/Dialog/DialogChanges'
import { notifySuccess } from '@/components'
import {
  getLastProgramNumber,
  getActiveProgram,
  getActiveSlide,
} from '@/components/Organisms/DIT/helpers'
import type { Slide } from '@/components/Organisms/DIT/types'

const BtnMakeCopy: React.FC<{ slides?: Slide[] }> = ({ slides }) => {
  const { id } = useParams()
  const activeProgramId = useAppSelector(
    ditSliceSelectors.stateDitActiveProgram
  )
  const PList = useAppSelector(ditSliceSelectors.stateDitPrograms)
  const slidesNumbers =
    slides?.map((slide) => Number(slide.name.match(/\d+/g))) || []

  const newName = Math.max(...slidesNumbers) || 1
  const { refetch } = useGetDitsQuery(Number(id))

  const [modalSetDit, setModalSetDit] = React.useState(false)
  const activeSlideId = useAppSelector(ditSliceSelectors.stateDitActiveSlide)

  const hasUnsavedChanges = useAppSelector(ditSliceSelectors.stateHasChanges)

  const [copyDit, { isLoading: copyLoading }] = useCreateCopySlideMutation()

  const handleCopy = async () => {
    setModalSetDit(true)
  }

  const approvedSend = async () => {
    // todo get last number
    const newId = newName + 1

    const name = `Слайд ${newId}`
    const res = await copyDit({
      deviceId: Number(id),
      programId: activeProgramId || 0,
      slideId: activeSlideId || 0,
      name: name,
    })
    // @ts-ignore
    if (res?.data?.success) {
      notifySuccess(`Слайд был удачно продублирован. Имя новго слайда: ${name}`)
    }
    setModalSetDit(false)
    refetch()
  }

  return (
    <>
      <LoaderFullScreen open={copyLoading} />
      <DialogChanges
        open={modalSetDit}
        loading={copyLoading}
        handleAgree={approvedSend}
        handleClose={() => setModalSetDit(false)}
        handleDisAgree={() => setModalSetDit(false)}
        message={'Вы действительно желаете продублировать данный слайд?'}
        btnSaveTitle={'Да'}
        btnCancelTitle={'Нет'}
      />
      <LoadingButton
        variant={'contained'}
        color='success'
        loading={false}
        onClick={handleCopy}
        disabled={hasUnsavedChanges}
      >
        Дублировать
      </LoadingButton>
    </>
  )
}

export default BtnMakeCopy
