import React from 'react'
import { LoadingButton } from '@mui/lab'
import {
  useGetDitsQuery,
  useSetActiveDitMutation,
} from '@/components/Organisms/DIT/service'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '@/hooks'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'
import { LoaderFullScreen } from '@/ui'
import { notifySuccess } from '@/components'

const BtnSendToDit: React.FC = () => {
  const { id } = useParams()
  const activeProgramId = useAppSelector(
    ditSliceSelectors.stateDitActiveProgram
  )
  const { refetch } = useGetDitsQuery(Number(id))
  const [setActiveDit, { isLoading: setActiveIsLoading }] =
    useSetActiveDitMutation()

  const approvedSendDit = async () => {
    if (activeProgramId) {
      const res = await setActiveDit({
        deviceId: Number(id),
        programId: activeProgramId,
      })
      // @ts-ignore
      if (res?.data?.success) {
        refetch()
        notifySuccess(`Программа была удачно отправлена.`)
      }
    }
  }

  return (
    <>
      <LoaderFullScreen open={setActiveIsLoading} />
      <LoadingButton
        variant={'contained'}
        loading={false}
        onClick={approvedSendDit}
        disabled={setActiveIsLoading}
      >
        Отправить
      </LoadingButton>
    </>
  )
}

export default BtnSendToDit
