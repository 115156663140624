import { api } from '@/api'
import { ISimpleResponse } from '@/modules/login/types'
import {
  AddUnitReqUser,
  ChangePassReq,
  ISimpleResponseUpdateUser,
  Organisation,
  OrganisationsRes,
  UnitsGetReqUser,
  UnitsListResUser,
  UserTypeRes,
} from '@/modules/user/types/userType'

const userService = api.injectEndpoints({
  endpoints: (build) => ({
    getUserProfile: build.query<UserTypeRes, string>({
      query: (userId) => ({
        url: '/users/profile',
        method: 'GET',
        params: {
          userId,
        },
      }),
      providesTags: (result, error, id) => {
        return [{ type: 'User', id }]
      },
      transformResponse: (res: UserTypeRes) => {
        if (!res.success) {
          //window.location.replace('/users')
        }
        return res
      },
    }),
    getOrganisations: build.query<Organisation[], string>({
      query: () => ({
        url: '/organizations/getAll',
        method: 'GET',
      }),
      transformResponse: (res: OrganisationsRes) => {
        return [...res.data]
      },
    }),
    changeUserPassword: build.mutation<ISimpleResponse, ChangePassReq>({
      query: (body) => ({
        url: '/users/changePassword',
        method: 'POST',
        body,
      }),
    }),
    changeUserStatus: build.mutation<
      ISimpleResponse,
      { userId: number; status: boolean }
    >({
      query: ({ userId, status }) => ({
        url: '/users/updateUserActive',
        method: 'POST',
        params: {
          userId,
          status,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'User', id: arg.userId },
      ],
    }),
    updateUserProfile: build.mutation<
      ISimpleResponseUpdateUser,
      { data: FormData; id: number }
    >({
      query: ({ data, id }) => ({
        url: '/users/profile',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: 'User', id: arg.id }]
      },
    }),
    deleteUserAvatar: build.mutation<ISimpleResponse, { id: number }>({
      query: ({ id }) => ({
        url: '/users/deleteAvatar',
        method: 'POST',
        params: { userId: id },
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: 'User', id: arg.id }]
      },
    }),
    searchUnitsUser: build.mutation<UnitsListResUser, UnitsGetReqUser>({
      query: (body) => ({
        url: '/users/getDevices',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: 'User' }]
      },
    }),
    addUnitsUser: build.mutation<ISimpleResponse, AddUnitReqUser>({
      query: (body) => ({
        url: '/users/addDevice',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: 'User' }]
      },
    }),
    deleteUnitsUser: build.mutation<ISimpleResponse, AddUnitReqUser>({
      query: (body) => ({
        url: '/users/deleteDevice',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: 'User' }]
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetUserProfileQuery,
  useGetOrganisationsQuery,
  useChangeUserPasswordMutation,
  useChangeUserStatusMutation,
  useUpdateUserProfileMutation,
  useDeleteUserAvatarMutation,
  useSearchUnitsUserMutation,
  useAddUnitsUserMutation,
  useDeleteUnitsUserMutation,
} = userService
