import { Dialog, notifySuccess } from '@/components'
import { CHART_HEIGHT } from '@/constants'
import { useActions, useAppSelector } from '@/hooks'
import RowTable from '@/modules/device-module/components/table/RowTable'
import {
  useGetDeviceDetailQuery,
  useUpdateDeviceMutation,
} from '@/modules/device-module/services/deviceService'
import { stateDeviceSelector } from '@/modules/device-module/store/stateSlice'
import { DeviceUpdate } from '@/modules/device-module/types/deviceType'
import { useGetUserProfileQuery } from '@/modules/user/services/userService'
import { UserTypes } from '@/types/typeUser'
import cn from '@/utils/cn'
import { useChangeSet } from '@/utils/useChangeSet'
import { Box, Button, Checkbox, TableBody, Typography } from '@mui/material'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import _ from 'lodash'
import React, { FC, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import { useLocation, useParams } from 'react-router-dom'

const s = cn('device-module-table')

interface Props {
  setLoading: any
}

const TableDevice: FC<Props> = ({ setLoading }) => {
  const { id } = useParams()
  const { pathname } = useLocation()

  const { data: currentUserData } = useGetUserProfileQuery('0')
  const currentUserType = currentUserData?.data.typeId

  const { refetch } = useGetDeviceDetailQuery(Number(id), {
    skip: pathname.includes('photos'),
  })

  const {
    isOnChange,
    device,
    selected,
    selectedFilters,
    disabledSaveBtn,
    selectedDevice,
    deleted,
  } = useAppSelector(stateDeviceSelector)
  const {
    setDevice,
    setSelected,
    setSelectedDevice,
    setClearDeleted,
    toggleOnChangeDevice,
  } = useActions()
  const [updateDeviceService] = useUpdateDeviceMutation()

  const [openSaveDialog, setOpenSaveDialog] = useState(false)

  const [error, setError] = React.useState(false)
  const [error2, setError2] = React.useState(false)

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const updatedItems = _.cloneDeep(device)
    if (updatedItems) {
      const [reorderedItem] = updatedItems.modules.splice(
        result.source.index,
        1
      )
      updatedItems.modules.splice(result.destination.index, 0, reorderedItem)
      //setSelected((prev) => result?.destination?.index ?? prev)
      updatedItems.modules.forEach((item, index) => {
        item.displaySequence = index + 1
      })
      setDevice(updatedItems)
    }
  }

  const isSelected = (id: number) => selected.indexOf(id) !== -1

  const onSubmit = async () => {
    setOpenSaveDialog(false)
    setLoading(true)
    try {
      const modules = device?.modules.map((item) => {
        return {
          moduleId: item.id,
          name: item.name,
          displaySequence: item.displaySequence,
        }
      })
      const data: DeviceUpdate = {
        deviceId: Number(id),
        modules: modules ?? [],
        deleted: deleted,
      }
      const res = await updateDeviceService(data).unwrap()
      if (res.success) {
        notifySuccess('Изменения сохранены')
        setClearDeleted()
        toggleOnChangeDevice()
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected: number[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  useChangeSet(() => {
    if (device?.modules && !selectedFilters.includes(0)) {
      const filters = device?.modules
        .filter((item) => selectedFilters.includes(item.state))
        .map((item) => item.id)
      setSelected(selected.filter((item) => filters.includes(item)))
    }
  }, [selectedFilters])

  return (
    <Box
      flex={1}
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      className={s()}
    >
      <DragDropContext onDragEnd={handleDragEnd}>
        <Dialog
          message='Внесенные изменения будут сохранены. Продолжить?'
          open={openSaveDialog}
          handleClose={() => setOpenSaveDialog(false)}
          handleAgree={onSubmit}
        />
        <TableContainer
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            maxHeight: {
              // xss:
              //   document.documentElement.clientHeight -
              //   CHART_HEIGHT -
              //   (isOnChange ? 190 : 150),
              lg:
                document.documentElement.clientHeight -
                CHART_HEIGHT -
                (isOnChange ? 300 : 185),
            },
            backgroundColor: '#fff',
          }}
        >
          <Table>
            <Droppable droppableId='table'>
              {(provided) => (
                <TableBody
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    transition: 'background-color 300ms linear',
                  }}
                >
                  <TableRow
                    sx={{
                      border: '2px solid rgba(0, 0, 0, .05)',
                    }}
                  >
                    <TableCell padding='checkbox'>
                      <Checkbox
                        checked={selectedDevice}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setSelectedDevice(event.target.checked)
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component='th'
                      scope='row'
                      padding='none'
                    >
                      <Box
                        display='flex'
                        alignItems='center'
                        gap={5}
                      >
                        <Typography
                          color='primary'
                          fontSize='1.2rem'
                        >
                          КУМ
                        </Typography>
                        {!isOnChange ? (
                          <Typography>
                            {currentUserType === UserTypes.superAdmin
                              ? device?.mac
                              : ''}
                          </Typography>
                        ) : null}
                      </Box>
                    </TableCell>
                    {isOnChange ? <TableCell /> : null}
                    {isOnChange ? (
                      <TableCell
                        component='th'
                        scope='row'
                        //padding='none'
                      >
                        <Box
                          display='flex'
                          alignItems='center'
                          gap={5}
                        >
                          <Typography>{device?.mac}</Typography>
                        </Box>
                      </TableCell>
                    ) : null}
                  </TableRow>
                  {/*{JSON.stringify(device)}*/}
                  {(device?.modules && !selectedFilters.includes(0)
                    ? device?.modules.filter((item) =>
                        selectedFilters.includes(item.state)
                      )
                    : device?.modules
                  )?.map((item, index) => {
                    const isItemSelected = isSelected(item.id)
                    // console.log(555, isItemSelected)
                    return (
                      <Draggable
                        key={index.toString()}
                        draggableId={index.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <RowTable
                            provided={provided}
                            snapshot={snapshot}
                            id={item.id}
                            item={item}
                            isItemSelected={isItemSelected}
                            index={index}
                            handleClick={handleClick}
                            isHVLamp={device?.is220v ?? false}
                            setError={setError}
                            setError2={setError2}
                            error={error}
                            error2={error2}
                          />
                        )}
                      </Draggable>
                    )
                  })}
                </TableBody>
              )}
            </Droppable>
          </Table>
        </TableContainer>
      </DragDropContext>
      <Box
        display='flex'
        justifyContent='flex-end'
        gap={2}
        p={{ xss: isOnChange ? 1 : 0.5, lg: isOnChange ? 2 : 0.5 }}
      >
        {isOnChange ? (
          <>
            <Button
              variant='contained'
              disabled={disabledSaveBtn}
              onClick={() => {
                if (error || error2) {
                  return
                }
                setOpenSaveDialog(true)
              }}
            >
              Сохранить
            </Button>
            <Button
              onClick={() => {
                refetch()
                setClearDeleted()
                toggleOnChangeDevice()
              }}
            >
              Отменить
            </Button>
          </>
        ) : null}
      </Box>
    </Box>
  )
}

export default TableDevice
