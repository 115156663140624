import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from '@/components/Organisms/DIT/DITTree/DITTree.module.scss'
import IconEditText from '@/components/Atoms/Icons/IconEditText'
import IconAddImage from '@/components/Atoms/Icons/IconAddImage'
import { IconButton } from '@mui/material'
import IconDelete from '@/components/Atoms/Icons/IconDelete'
import type {
  Program,
  SlideConstructor,
} from '@/components/Organisms/DIT/types'
import { useActions, useAppSelector } from '@/hooks'
import { ditSliceSelectors } from '@/components/Organisms/DIT/store'
type Props = {
  program: Program
  editMode: boolean
  item: SlideConstructor
  programId: number
  slideId: number
}
const DITItem: React.FC<Props> = ({
  item,
  editMode,
  programId,
  slideId,
  program,
}) => {
  const activeProgram = useAppSelector(ditSliceSelectors.stateDitActiveProgram)
  const activeSlide = useAppSelector(ditSliceSelectors.stateDitActiveSlide)
  const activeSlideItem = useAppSelector(
    ditSliceSelectors.stateDitActiveSlideItem
  )
  const { ditSetActiveSlideItem, ditRemoveSlideItem, ditUpdateSlideItemName } =
    useActions()
  const handleActiveSlide = () => {
    ditSetActiveSlideItem({
      programId: programId,
      slideId: slideId,
      slideItemId: item.id,
    })
  }
  const removeHandler = () => {
    ditRemoveSlideItem({
      programId: programId,
      slideId: slideId,
      slideItemId: item.id,
    })
  }
  const handleChangeName = (e: any) => {
    ditUpdateSlideItemName({
      programId: programId,
      slideId: slideId,
      itemId: item.id,
      name: e.target.value,
    })
  }
  const isActive =
    `${programId}${slideId}${item.id}` ===
    `${activeProgram}${activeSlide}${activeSlideItem}`
  return (
    <div className={cn(styles.ProgramRow, styles.ItemsWrap)}>
      <div
        className={cn(styles.ProgramRowLeft, {
          [styles.ProgramTitleActive]: isActive,
        })}
        onClick={editMode ? () => {} : handleActiveSlide}
        style={{ cursor: 'pointer' }}
      >
        {item.type === 'text' ? <IconEditText /> : <IconAddImage />}
        {editMode ? (
          <>
            <input
              defaultValue={item.name}
              onChange={handleChangeName}
              className={styles.EditableNameInput}
              type='text'
            />
          </>
        ) : (
          <span onClick={handleActiveSlide}>{item.name}</span>
        )}
      </div>
      {editMode && (
        <IconButton onClick={removeHandler}>
          <IconDelete />
        </IconButton>
      )}
    </div>
  )
}

export default DITItem
