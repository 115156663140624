import { RefObject, useEffect } from 'react'

type Handler = (event: MouseEvent | TouchEvent) => void

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: Handler
) {
  useEffect(() => {
    const controller = new AbortController()
    const listener = (event: MouseEvent | TouchEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return
      }
      handler(event)
    }
    document.addEventListener('mousedown', listener, {
      signal: controller.signal,
    })
    document.addEventListener('touchstart', listener, {
      signal: controller.signal,
    })
    return () => {
      controller.abort()
    }
  }, [ref, handler])
}
