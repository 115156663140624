import { FC } from 'react'
import { TypeIcon } from '@/components/Atoms/Icons/types'

const Icon: FC<TypeIcon> = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='-3 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={
        'MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root'
      }
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.0938 3.74953C18.0938 5.42432 16.7361 6.78201 15.0613 6.78201C13.3865 6.78201 12.0288 5.42432 12.0288 3.74953C12.0288 2.07473 13.3865 0.717041 15.0613 0.717041C16.7361 0.717041 18.0938 2.07473 18.0938 3.74953ZM16.5775 3.74953C16.5775 4.58692 15.8987 5.26577 15.0613 5.26577C14.2239 5.26577 13.5451 4.58692 13.5451 3.74953C13.5451 2.91213 14.2239 2.23328 15.0613 2.23328C15.8987 2.23328 16.5775 2.91213 16.5775 3.74953Z'
        fill='#6A6C6D'
      />
      <path
        d='M0.0332369 2.7375H9.03969V4.74202L2.03843 4.73616V16.7342L14.0365 16.7401V9.73639H16.0435L16.0438 18.7388H0.0332031L0.0332369 2.7375Z'
        fill='#6A6C6D'
      />
      <path
        d='M12.0274 9.74523L4.03405 9.74657L4.03428 11.7482L12.0276 11.7468L12.0274 9.74523Z'
        fill='#6A6C6D'
      />
      <path
        d='M4.03428 12.7499H12.0274L12.0276 14.7289L4.03405 14.792L4.03428 12.7499Z'
        fill='#6A6C6D'
      />
      <path
        d='M12.0276 6.75167L4.03381 6.75312L4.03405 8.74258L12.0279 8.74113L12.0276 6.75167Z'
        fill='#6A6C6D'
      />
    </svg>
  )
}

export default Icon
