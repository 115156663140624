import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import Radio from '@mui/material/Radio'
import type { Program } from '@/components/Organisms/DIT/types'
import {
  useGetDitsQuery,
  useSetChangeActiveMutation,
} from '@/components/Organisms/DIT/service'
import { useParams } from 'react-router-dom'
import { notifySuccess } from '@/components'
import { LoaderFullScreen } from '@/ui'
type Props = {
  program: Program
  editMode: boolean
}
const DITRadio: React.FC<Props> = ({ program, editMode }) => {
  const { id } = useParams()
  const { refetch } = useGetDitsQuery(Number(id))
  const [changeActive, { isLoading }] = useSetChangeActiveMutation()

  const handleRadio = async (isDefault: boolean) => {
    const res = await changeActive({
      deviceId: Number(id),
      programId: program?.id,
      active: !program?.enabled,
      defaultProgram: isDefault,
    })
    // @ts-ignore
    // if (res?.data?.success) {
    //   notifySuccess(`чтото удачное`)
    // }
    refetch()
  }
  const isChecked = program?.enabled
  return (
    <>
      <LoaderFullScreen open={isLoading} />

      {program?.relationShip ? (
        <Checkbox
          onChange={() => handleRadio(false)}
          disabled={editMode}
          checked={isChecked}
          size='small'
        />
      ) : (
        <Radio
          checked={program?.enabled}
          onChange={() => handleRadio(true)}
          disabled={editMode}
          size='small'
        />
      )}
    </>
  )
}

export default DITRadio
