import * as React from 'react'
import { List, arrayMove } from 'react-movable'
import type { Slide, Program } from '@/components/Organisms/DIT/types'
import DITSlide from '@/components/Organisms/DIT/DITTree/DITSlide'
import styles from './DITTree.module.scss'
import { OnChangeMeta } from 'react-movable/lib/types'
import { useActions } from '@/hooks'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'

type Props = {
  program: Program
  slides: Slide[]
  editMode: boolean
}
const DITSlides: React.FC<Props> = ({ slides, editMode, program }) => {
  const { ditSetUnsavedTreeChanges, ditSetSlideItems } = useActions()
  const [items, setItems] = React.useState(slides || [])
  React.useEffect(() => {
    setItems(slides)
  }, [slides])
  const handleChangeOrdering = ({ oldIndex, newIndex }: OnChangeMeta) => {
    const orderedSlidesArr = arrayMove(items, oldIndex, newIndex).map(
      (slide, index) => {
        return { ...slide, index: index + 1 }
      }
    )
    setItems(orderedSlidesArr)
    ditSetUnsavedTreeChanges(true)
    ditSetSlideItems({ items: orderedSlidesArr, programId: program.id })
  }
  return (
    <div style={{ paddingLeft: editMode ? '14px' : '0px' }}>
      {!editMode ? (
        <div>
          {slides.map((value) => (
            <DITSlide
              program={program}
              programId={program.id}
              key={value.index}
              slide={value}
              editMode={editMode}
            />
          ))}
        </div>
      ) : (
        <List
          transitionDuration={0}
          values={items}
          onChange={handleChangeOrdering}
          renderList={({ children, props }) => <div {...props}>{children}</div>}
          renderItem={({ value, props, isDragged }) => {
            return (
              <div
                {...props}
                key={value.index}
                className={styles.DraggedItem}
              >
                {editMode && program.slides.length > 1 && (
                  <span
                    className={styles.DragCursor}
                    data-movable-handle
                  >
                    <DragIndicatorIcon />
                  </span>
                )}
                <DITSlide
                  program={program}
                  programId={program.id}
                  key={value.index}
                  slide={value}
                  editMode={editMode}
                />
              </div>
            )
          }}
        />
      )}
    </div>
  )
}

export default DITSlides
